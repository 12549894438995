import 'normalize.css'
import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import { router } from './router'
import { analytics } from './utils/analytics'
import { isValidBrowser } from './utils/browser'

if (isValidBrowser) {
  createApp(App).use(router).use(analytics).mount('#app')
} else {
  window.showUnsupportedBrowserMessage()
}
