<template>
  <ConfettiForestBackground>
    <main :class="$style.wrapper">
      <img
        draggable="false"
        :class="$style.gameTitle"
        :src="getThemeAsset('mini-games.svg')"
      />
      <section
        :class="$style.competition"
        :style="{
          '--wood-competition-pattern': getThemeImageUrl(
            'wood-competition-pattern.svg',
          ),
          ...getThemeImageStyle('wood-competition-top.svg'),
        }"
      >
        <div v-if="competition" :class="$style.content">
          <img
            draggable="false"
            :class="$style.companyLogo"
            :src="company ? company.logo : undefined"
          />

          <template v-if="isCompetitionStarted">
            <ConfettiTrophyBox
              :class="$style.trophyBox"
              v-bind="{ competition }"
            />
            <ConfettiCompetitionRows :rows="finishedGames" />
            <div v-if="unFinishedGames.length > 0" :class="$style.processTitle">
              Unfinished Yet
            </div>
            <ConfettiCompetitionRows :rows="unFinishedGames" disable />
          </template>

          <transition v-else name="fade" appear>
            <div :class="$style.notice">
              Competition results will appear here<br />once teams finish the
              game
            </div>
          </transition>
        </div>
      </section>
    </main>
  </ConfettiForestBackground>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'
import { Competition } from '../../types'
import { ConfettiCompetitionRows } from '../components/ConfettiCompetitionRows.vue'
import { ConfettiCompetitionScore } from '../components/ConfettiCompetitionScore.vue'
import { ConfettiForestBackground } from '../components/ConfettiForestBackground.vue'
import { ConfettiPlayersListVertical } from '../components/ConfettiPlayersListVertical.vue'
import { ConfettiTrophyBox } from '../components/ConfettiTrophyBox.vue'
import { getCompetition } from '../utils/api'
import { useTheme } from '../utils/useTheme'

export default defineComponent({
  components: {
    ConfettiTrophyBox,
    ConfettiCompetitionScore,
    ConfettiPlayersListVertical,
    ConfettiForestBackground,
    ConfettiCompetitionRows,
  },
  setup() {
    const route = useRoute()
    const isLoading = ref(false)
    const competition = ref<Competition>()
    const isCompetitionStarted = computed(() =>
      Boolean(
        competition.value?.unFinishedGames.length ||
          competition.value?.finishedGames.length,
      ),
    )
    const company = computed(() => {
      return competition.value?.company
    })
    const finishedGames = computed(() =>
      competition.value?.finishedGames.slice(3),
    )

    const unFinishedGames = computed(() => competition.value?.unFinishedGames)

    const { setTheme, getThemeAsset, getThemeImageStyle, getThemeImageUrl } =
      useTheme()

    onBeforeMount(async () => {
      try {
        isLoading.value = true
        competition.value = await getCompetition(
          route.params.competitionId as string,
        )
        setTheme(competition.value.theme)
      } catch (err) {
        console.error(err)
      } finally {
        isLoading.value = false
      }
    })

    return {
      isCompetitionStarted,
      competition,
      finishedGames,
      company,
      isLoading,
      unFinishedGames,
      getThemeAsset,
      getThemeImageStyle,
      getThemeImageUrl,
    }
  },
})
</script>

<style module>
.wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

.competition {
  position: relative;
  padding: 6rem 6rem 0;
  width: 80rem;
  background-size: 80rem auto;
  background-position: top center;
  background-repeat: no-repeat;
  flex: 1;
}

.competition::before {
  content: '';
  position: absolute;
  background-image: var(--wood-competition-pattern);
  background-size: 80rem auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 34rem;
  transform: translateY(-1px);
}

.content {
  padding: 2rem 4rem;
  height: 100%;
}

.content::after {
  content: '';
  height: 8rem;
  display: block;
}

.gameTitle {
  height: 10.125rem;
  position: relative;
  top: 1rem;
  flex: 0 0 auto;
  margin-bottom: -3.75rem;
  margin-top: auto;
}

.companyLogo {
  height: 3rem;
  margin: 0 auto;
  display: block;
}

.trophyBox {
  margin-top: 2rem;
  position: relative;
  z-index: 0;
}
.processTitle {
  color: white;
  position: relative;
  font-family: Skranji;
  font-size: 1.5rem;
  line-height: 1.33;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: -1rem;
}

.notice {
  font-size: 1.5rem;
  line-height: 2rem;
  margin: auto 0;
  opacity: 0.64;
  padding: 2rem 0 8rem;
  text-align: center;
  color: #fff;
}
</style>
