<template>
  <main :class="$style.loginScreen">
    <img
      draggable="false"
      :class="$style.gameTitle"
      :src="getThemeAsset('mini-games.svg')"
    />
    <div
      :class="$style.byConfetti"
      :style="
        themeConfig && { color: `rgb(${themeConfig.backgroundTextColor})` }
      "
    >
      by Confetti
    </div>
    <div
      :class="$style.tagline"
      :style="
        themeConfig && { color: `rgb(${themeConfig.backgroundTextColor})` }
      "
    >
      Play and make the world a better place
    </div>
    <div
      :class="$style.woodSign"
      class="sign"
      :style="{
        '--wood-sign-post': getThemeImageUrl('wood-sign-post.svg'),
        '--wood-sign': getThemeImageUrl('wood-sign.svg'),
      }"
    >
      <div :class="$style.woodSignContent">
        <slot />
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useTheme } from '../utils/useTheme'

export const ConfettiLoginScreen = defineComponent({
  setup() {
    const { getThemeAsset, getThemeImageUrl, themeConfig } = useTheme()

    return {
      themeConfig,
      getThemeAsset,
      getThemeImageUrl,
    }
  },
})

export default ConfettiLoginScreen
</script>

<style module>
.loginScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(var(--base-color));
  text-align: center;
  overflow: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.gameTitle {
  width: 30.5rem;
  margin-bottom: -2.5rem;
  margin-top: auto;
}

.byConfetti {
  text-transform: uppercase;
  line-height: 1.5rem;
  opacity: 0.64;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.25rem;
  text-indent: 0.25rem;
}

.tagline {
  line-height: 2rem;
  font-size: 2rem;
  font-family: Caveat;
  margin-bottom: auto;
}

.woodSignContent {
  z-index: 1;
  width: 24rem;
  margin: 2.5rem 0 0;
}

.woodSign {
  margin-top: auto;
  display: flex;
  justify-content: center;
  position: relative;
  height: 24rem;
  width: 100%;
  margin-top: 1rem;
}

.woodSign::before,
.woodSign::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top center;
}

.woodSign::before {
  background-image: var(--wood-sign-post);
  background-size: 5rem auto;
}

.woodSign::after {
  background-image: var(--wood-sign);
  background-size: 28rem 18rem;
}
</style>

<style scoped>
.page-enter-from,
.page-leave-to,
.page-enter-active .sign {
  opacity: 0;
  transform: translateY(2rem);
}

.page-enter-active,
.sign {
  transition: opacity 0.25s ease-in,
    transform 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.page-leave-active {
  transition: opacity 0.25s ease-out,
    transform 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
</style>
