<template>
  <main v-if="isPlayerReady && themeScoreType" :class="$style.screen">
    <div
      :class="$style.titleContainer"
      :style="{ color: `rgb(${themeConfig?.backgroundTextColor})` }"
    >
      <div :class="$style.title">Congrats!</div>
      <div :class="$style.subtitle">
        <template v-if="scoreItems">
          Thanks to your efforts <strong>{{ scoreItems }}</strong>
          {{ themeScoreType.getTitle(scoreItems) }} will be
          {{ themeScoreType.actionPast }}
        </template>
        <template v-else>
          Although you didn't get enough points, we will still
          {{ themeScoreType.action }} one {{ themeScoreType.getTitle(1) }} for
          you<br />as a token of appreciation for your participation!
        </template>
      </div>
    </div>
    <div
      :class="
        competitionLink
          ? $style.scoreItems
          : $style.scoreItemsWithoutCompetition
      "
    >
      <img
        v-for="index in scoreItems || 1"
        :key="index"
        draggable="false"
        :class="$style.tree"
        :src="getThemeAsset('plant.svg')"
      />
    </div>
    <div
      :class="$style.shareButtonText"
      :style="{ '--overlay-color': themeConfig?.overlayColor }"
    >
      Curious how other teams did?
    </div>
    <router-link v-if="competitionLink" :to="competitionLink" target="_blank">
      <ConfettiPrimaryButton :class="$style.shareButton">
        Open Leaderboard
      </ConfettiPrimaryButton>
    </router-link>
    <div :class="$style.shareContainer" @click="shareModalVisible = true">
      <img
        :src="getThemeAsset('button-share-icon.svg')"
        :class="$style.shareIcon"
      />
    </div>
    <ConfettiShareModal
      v-if="shareModalVisible"
      :scoreItems="scoreItems"
      :class="$style.shareModal"
      @close="shareModalVisible = false"
    />
  </main>
  <ConfettiLoginScreen v-else>
    <div :class="$style.sign">
      <div>This game is</div>
      <div :class="$style.signOver">OVER</div>
    </div>
  </ConfettiLoginScreen>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useGame } from '../utils/useGame'
import { useTheme } from '../utils/useTheme'
import { ConfettiLoginScreen } from './ConfettiLoginScreen.vue'
import { ConfettiPrimaryButton } from './ConfettiPrimaryButton.vue'
import { ConfettiShareModal } from './ConfettiShareModal.vue'

export const ConfettiGameOverScreen = defineComponent({
  components: {
    ConfettiPrimaryButton,
    ConfettiLoginScreen,
    ConfettiShareModal,
  },
  setup() {
    const route = useRoute()

    const { game, isPlayerReady } = useGame(route.params.code as string)
    const shareModalVisible = ref(false)
    const scoreItems = computed(() => game.value?.scoreItems)
    const competitionLink = computed(() =>
      game.value?.competition
        ? `/competition/${game.value?.competition}`
        : false,
    )

    const { getThemeAsset, themeConfig, themeScoreType } = useTheme()

    return {
      scoreItems,
      isPlayerReady,
      shareModalVisible,
      competitionLink,
      getThemeAsset,
      themeConfig,
      themeScoreType,
    }
  },
})

export default ConfettiGameOverScreen
</script>

<style module>
.screen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 2rem;
}

.titleContainer {
  text-align: center;
  margin: auto 0;
}

.title {
  font-family: Skranji;
  font-weight: bold;
  font-size: 6rem;
  line-height: 7rem;
  margin-top: -2rem;
}

.subtitle {
  font-family: Caveat;
  font-size: 2rem;
  line-height: 2rem;
  margin-top: 1rem;
}

.scoreItems {
  display: flex;
  margin-bottom: 6rem;
}

.scoreItemsWithoutCompetition {
  display: flex;
  margin-bottom: 10.5rem;
}

.tree + .tree {
  margin-left: 1.5rem;
}

.tree:nth-child(odd) {
  transform: scaleX(-1);
}

.shareButton {
  z-index: 1;
  /* remove once bug fixed */
  height: 4rem !important;
  padding: 0 2rem;
  margin-top: 0.5rem;
}

.shareModal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.sign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.5rem;
  text-shadow: 0 0.25rem 0.5rem rgba(255, 255, 255, 0.16);
  font-family: Skranji;
  font-size: 1rem;
  color: #fff;
  height: 13rem;
}

.signOver {
  line-height: 4.5rem;
  font-size: 4rem;
}

.shareContainer {
  bottom: 2.5rem;
  right: 2.5rem;
  position: absolute;
  width: 3rem;
  height: 3rem;
  padding: 0.75rem;
  border-radius: 1.25rem;
  box-shadow: 0 0.125rem 0 0 #b8babd, inset 0 0.125rem 0 0 rgb(255 255 255 / 8%),
    0 0.25rem 0 0 #b8babd;
  background-image: linear-gradient(to bottom, #ffffff, #e0e0e0);
  cursor: pointer;
  transition: 0.2s ease;
}

.shareContainer:hover {
  transform: translateY(-0.25rem);
  box-shadow: 0 0.125rem 0 0 #b8babd,
    inset 0 2rem 2rem -1rem rgb(255 255 255 / 16%), 0 0.5rem 0 0 #b8babd;
}

.shareContainer:active {
  transform: translateY(-0.125rem);
  box-shadow: 0 0.125rem 0 0 #b8babd,
    inset 0 2rem 2rem -1rem rgb(255 255 255 / 8%), 0 0.375rem 0 0 #b8babd;
}
.shareIcon {
  height: 1.5rem;
}

.shareButtonText {
  text-shadow: 0 0.125rem 0.5rem rgb(var(--overlay-color));
  font-weight: bold;
  line-height: 1;
  color: white;
}
</style>
