export interface GameMetadata {
  _id: string
  _rev: string
  _updatedAt: string
  code: string
  maxPlayers: number
  company: Company
  competition?: string
}

export interface GameData {
  team?: string
  players?: GamePlayer[]
  questions?: GameQuestion[]
  lastQuestionStarted: boolean
}

export interface PlayerMetadata {
  _key: string
  name: string
  isReady?: boolean
}

export type Player = PlayerMetadata & {
  isYou: boolean
  isActive: boolean
  emoji: string
}

export type GamePlayer = PlayerMetadata

export type GameResult = GameMetadata &
  GameData & {
    quiz: Quiz
  }

export type GameRound = Round & {
  questionKeys: string[]
  isLastRound: boolean
}

export interface GameTeam {
  name: string
  emoji: string
}

export type Game = GameMetadata & {
  currentQuestion?: GameQuestion & { offsetStartedAt?: boolean }
  currentRound: GameRound
  lastAnswer?: Answer
  coins: number
  scoreItems: number
  readyPlayers: string[]
  team?: GameTeam
  introText?: string
  introImage?: string
}

export interface Question {
  _key: string
  image?: string
  text?: string
}

export interface Answer {
  image?: string
  text: string
}

export type GameQuestion = Question & {
  startedAt?: string
  answeredAt?: string
}

export type RoundQuestion = Question & {
  possibleAnswers?: string[]
  answer: Answer
}

export enum RoundType {
  Red = 'red',
  Orange = 'orange',
  Yellow = 'yellow',
  Green = 'green',
  Blue = 'blue',
  Indigo = 'indigo',
  Purple = 'purple',
  Pink = 'pink',
}

export interface Round {
  type: RoundType
  title: string
  description: string
  duration: number
  example: RoundQuestion
}

export interface Quiz {
  rounds: (Round & { questions: RoundQuestion[] })[]
  theme?: Themes
  introText?: string
  introImage?: string
}

export interface MemberInfo {
  name: string
  ts?: number
}

export interface Member {
  id: string
  info: MemberInfo
}

export interface MembersInfo {
  [id: string]: MemberInfo
}

export type TokenPayload = {
  code: string
  playerKey: string
} & MemberInfo

export interface Company {
  logo: string
  name: string
  url?: string
}

export interface CompetitionResult {
  lastQuestionKey: string
  company: Company
  games: GameData[]
  theme: Themes
}

export interface CompetitionGame {
  isYourTeam: boolean
  place?: number
  team?: GameTeam
  scoreItems: number
  coins: number
  players: {
    isYou: boolean
    name: string
  }[]
  isFinished: boolean
}

export interface Competition {
  company: Company
  finishedGames: CompetitionGame[]
  unFinishedGames: CompetitionGame[]
  theme: Themes
}

export enum Themes {
  CLASSIC = 'classic',
  HALLOWEEN = 'halloween',
  WINTER = 'winter',
  AAPI = 'aapi',
  BHC = 'bhc',
  WOMENS_HISTORY = 'womens_history',
  EARTH_DAY = 'earth_day',
  'LGBTQIA+' = 'lgbtqia+',
  'HISPANIC_&_LATINX' = 'hispanic_&_latinx',
}
