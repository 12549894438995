<template>
  <div class="modal-content">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const ConfettiModalContent = defineComponent({})

export default ConfettiModalContent
</script>

<style scoped>
.modal-content {
  background: #fff;
  border-radius: 3rem;
  box-shadow: var(--static-high-base-shadow);
  margin: 2rem;
  padding: 3rem;
  text-align: center;
  transition: opacity 0.3s ease, transform 0.3s ease;
}
</style>
