import * as FullStory from '@fullstory/browser'
import * as Sentry from '@sentry/browser'
import SentryFullStory from '@sentry/fullstory'
import { Dedupe } from '@sentry/integrations/esm/dedupe'
import { RewriteFrames } from '@sentry/integrations/esm/rewriteframes'
import { AxiosError } from 'axios'
import { Plugin } from 'vue'
import { devMode, dsn, orgId, root, sentryOrg } from './analyticsConfig'

FullStory.init({ orgId, devMode })
Sentry.init({
  dsn,
  enabled: !devMode,
  integrations: [
    new RewriteFrames({ root }),
    new SentryFullStory(sentryOrg as string),
    new Dedupe(),
  ],
})

export const analytics: Plugin = (app) => {
  app.config.errorHandler = (err) => {
    if (!(err instanceof Error)) return

    console.error(err)

    const statusCode = (err as AxiosError).request?.status
    if (statusCode === 0 || !window.navigator.onLine) {
      alert(
        'You are experiencing connectivity issues - Please check your internet connection and try again',
      )
      return
    }

    Sentry.captureException(err)

    const data = (err as AxiosError).response?.data
    const message = typeof data === 'string' ? data.split('\n')[0] : err.message
    alert(message)
  }
}
