<template>
  <div
    :class="[$style.frame, { [$style.big]: big }]"
    :style="
      themeConfig && {
        backgroundImage: themeConfig.contentWrapperStyle.background,
        '--inner': themeConfig.contentWrapperStyle.inner,
        '--outer': themeConfig.contentWrapperStyle.outer,
      }
    "
  >
    <div :class="$style.content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useTheme } from '../utils/useTheme'

export const ConfettiGameFrame = defineComponent({
  props: {
    big: Boolean,
  },
  setup() {
    const { themeConfig } = useTheme()

    return {
      themeConfig,
    }
  },
})

export default ConfettiGameFrame
</script>

<style module>
.frame {
  border-radius: 3rem;
}

.frame::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0.25rem;
  border-radius: 2.75rem;
  background-image: var(--inner);
}

.frame::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0.375rem;
  border-radius: 2.75rem;
  background-image: var(--outer);
}

.frame.big {
  border-radius: 4rem;
}

.frame.big::before {
  border-radius: 3.75rem;
}

.frame.big::after {
  border-radius: 3.625rem;
}

.content {
  position: relative;
  height: 100%;
  z-index: 1;
}
</style>
