import axios from 'axios'
import { Competition, Game, Themes } from '../../types'

const api = axios.create({ baseURL: '/api' })

export const setSocketId = (socketId?: string) => {
  api.defaults.headers['x-socket-id'] = socketId
}

export const getGame = async (code: string) => {
  const { data } = await api.get<Game>(`/game/${code}`)
  return data
}

export const getGameTheme = async (code: string) => {
  const { data } = await api.get<Themes>(`/game/${code}/theme`)
  return data
}

export const getCompetition = async (id: string) => {
  const { data } = await api.get<Competition>(`/competition/${id}`)
  return data
}

export const joinGame = async (code: string, name: string) => {
  const { data } = await api.post<Game>(`/game/${code}/join`, { name })
  return data
}

export const setPlayerReady = async (code: string) => {
  const { data } = await api.post<Game>(`/game/${code}/ready`)
  return data
}

export const loadNextQuestion = async (code: string, questionKey?: string) => {
  const { data } = await api.post<Game>(`/game/${code}/question`, {
    questionKey,
  })
  return data
}

export const startQuestion = async (code: string, questionKey: string) => {
  const { data } = await api.post<Game>(
    `/game/${code}/question/${questionKey}/start`,
  )
  return data
}

export const submitAnswer = async (
  code: string,
  questionKey: string,
  content: string,
) => {
  const { data } = await api.post<Game>(
    `/game/${code}/question/${questionKey}/answer`,
    { content },
  )
  return data
}

export const getTimeOffset = async () => {
  const start = Date.now()
  const { data } = await api.get<{ now: number }>('/now')
  const end = Date.now()
  const roundtrip = end - start
  const delta = end - data.now
  const offset = delta + roundtrip / 2
  return offset
}
