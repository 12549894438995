<script lang="ts">
import { defineComponent, watch, h, shallowRef } from 'vue'

export const fetchContent = (src: string) =>
  fetch(src).then((res) => res.text())

const toAttrs = (text: string) => {
  const parser = new DOMParser()
  const result = parser.parseFromString(text, 'text/xml')
  const { innerHTML, attributes } = result.firstChild as SVGSVGElement
  const attrs: Record<string, string> = { innerHTML }
  for (const { name, value } of Object.values(attributes)) attrs[name] = value
  return attrs
}

export const ConfettiInlineSvg = defineComponent({
  props: {
    content: String,
    src: String,
  },
  setup(props) {
    const attrs = shallowRef<Record<string, string>>()

    watch(
      props,
      async ({ src, content }) => {
        try {
          if (content) {
            attrs.value = toAttrs(content)
          } else if (src) {
            const text = await fetchContent(src)
            if (src !== props.src) return
            attrs.value = toAttrs(text)
          }
        } catch (err) {
          console.error(err)
          attrs.value = undefined
        }
      },
      { immediate: true },
    )

    return () => h('svg', attrs.value)
  },
})

export default ConfettiInlineSvg
</script>
