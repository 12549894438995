<template>
  <label :class="$style.label" role="button">
    <input v-model="toggle" type="checkbox" hidden v-bind="{ disabled }" />
    <div :class="$style.checkbox">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
        <path
          fill-rule="nonzero"
          d="M40 59.51l35.76-35.75a6 6 0 018.49 8.48l-40 40a6 6 0 01-8.5 0l-24-24a6 6 0 018.5-8.48L40 59.5z"
        />
      </svg>
    </div>
    <span>
      <slot />
    </span>
  </label>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export const ConfettiCheckbox = defineComponent({
  props: {
    disabled: {
      type: Boolean,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const toggle = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    })

    return {
      toggle,
    }
  },
})

export default ConfettiCheckbox
</script>

<style module>
.label {
  display: flex;
  align-items: center;
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);
}

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  background: rgba(255, 255, 255, 0.88);
  border: 2px solid rgba(0, 0, 0, 0.24);
  box-shadow: 0px 2px 0px rgba(87, 56, 23, 0.48);
  border-radius: 12px;
  transition: 0.2s ease;
}

.checkbox svg {
  width: 1.25rem;
  height: 1.25rem;
  transition: 0.2s ease;
  fill: rgba(48, 22, 61, 0.8);
  opacity: 0;
}

:checked + .checkbox svg,
.label:hover .checkbox svg {
  opacity: 1;
}

:checked + .checkbox svg {
  fill: #30163d;
}

:checked:hover + .checkbox svg {
  fill: rgba(48, 22, 61, 0.8);
}
</style>
