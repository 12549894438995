import { once } from 'lodash-es'
import { computed, ref } from 'vue'
import { Themes } from '../../types'
import { themes } from '../themes/config'
import { ScoreType, scoreTypes } from '../themes/scoreTypes'

export const useTheme = once(() => {
  const theme = ref<Themes>()

  const setTheme = async (value: Themes) => {
    theme.value = value
  }

  const themeConfig = computed(() => theme.value && themes[theme.value])

  const getThemeAsset = (path: string) =>
    theme.value &&
    new URL(`/themes/${theme.value}/${path}`, import.meta.url).href

  const getThemeImageUrl = (path: string) => {
    const url = getThemeAsset(path)
    return url && `url(${url})`
  }

  const getThemeImageStyle = (path: string) => ({
    backgroundImage: getThemeImageUrl(path),
  })

  const themeScoreType = computed<ScoreType | undefined>(() => {
    if (!themeConfig.value?.scoreType) return
    const scoreType = scoreTypes[themeConfig.value.scoreType]
    return {
      ...scoreType,
      ...themeConfig.value.overrideScoreType,
    }
  })

  return {
    setTheme,
    theme,
    themeConfig,
    getThemeAsset,
    getThemeImageUrl,
    getThemeImageStyle,
    themeScoreType,
  }
})
