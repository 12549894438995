<template>
  <form :class="$style.form" @submit.prevent="onSubmit">
    <div v-if="isGameNotFound" :class="$style.notFound">Game Not Found</div>
    <fieldset v-else :disabled="isLoading">
      <label :class="$style.label">
        <input
          v-model.trim="name"
          autofocus
          autocomplete="off"
          placeholder="Your Name"
          maxlength="32"
          :class="$style.input"
          :style="getThemeImageStyle('icon-user.svg')"
        />
      </label>
      <ConfettiPrimaryButton :class="$style.button" :loading="isLoading">
        Join Game
        <input type="submit" :disabled="!isNameValid" hidden />
      </ConfettiPrimaryButton>
    </fieldset>
  </form>
</template>

<script lang="ts">
import { AxiosError } from 'axios'
import { computed, defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { ConfettiPrimaryButton } from '../components/ConfettiPrimaryButton.vue'
import { joinGame } from '../utils/api'
import { useGame } from '../utils/useGame'
import { useLoading } from '../utils/useLoading'
import { useTheme } from '../utils/useTheme'

export const ConfettiLoginForm = defineComponent({
  components: {
    ConfettiPrimaryButton,
  },
  setup() {
    const route = useRoute()
    const code = route.params.code as string
    const { setGame } = useGame(code)

    const name = ref('')
    const isNameValid = computed(() => Boolean(name.value.length))
    const isGameNotFound = ref(false)

    const [join, isLoading] = useLoading(async (name: string) => {
      await joinGame(code, name).then(setGame)
    })

    const onSubmit = async () => {
      if (!isNameValid.value) return

      try {
        await join(name.value)
      } catch (err) {
        const statusCode = (err as AxiosError).response?.status
        if (statusCode !== 404) throw err
        isGameNotFound.value = true
      }
    }

    const { getThemeImageStyle } = useTheme()

    return {
      isLoading,
      isGameNotFound,
      isNameValid,
      onSubmit,
      name,
      getThemeImageStyle,
    }
  },
})

export default ConfettiLoginForm
</script>

<style module>
.form {
  padding: 2rem;
}

.form fieldset {
  display: contents;
  border: none;
  padding: 0;
}

.button {
  margin-top: 1rem;
  /* TODO: remove important once we fix the css order issue */
  height: 4rem !important;
}

.label {
  display: flex;
  position: relative;
  height: 4rem;
  border-radius: 1.5rem;
  box-shadow: 0 0.125rem 0 0 #522c00,
    -0.25rem 0.25rem 1rem 0 rgba(255, 170, 0, 0.16),
    inset 0 0.125rem 0 0 rgba(255, 255, 255, 0.16);
  background-image: linear-gradient(to bottom, #ff8800, #ffaa00, #ff9100);
}

.input {
  flex: 1;
  margin: 0.125rem;
  background: #fff;
  border-radius: 1.375rem;
  box-shadow: inset 0 0.125rem 0 0 #ff8800;
  padding: 0 1rem 0 3.25rem;
  line-height: 1.5rem;
  font-size: 1.25rem;
  color: #003652;
  background-position: 1rem center;
  background-repeat: no-repeat;
  background-size: 1.75rem;
}

.notFound {
  color: #fff;
}
</style>
