<template>
  <div
    :class="[$style.icon, { [$style.small]: small }]"
    :style="{
      '--icon-tree': getThemeImageUrl('icon-tree.svg'),
    }"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useTheme } from '../utils/useTheme'

export const ConfettiTreeIcon = defineComponent({
  props: {
    small: Boolean,
  },
  setup() {
    const { getThemeImageUrl } = useTheme()

    return {
      getThemeImageUrl,
    }
  },
})

export default ConfettiTreeIcon
</script>

<style module>
.icon {
  position: relative;
  border-radius: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  box-shadow: 0 0.125rem #0e8e39, 0 0.125rem rgba(0, 0, 0, 0.24),
    inset 0 0.125rem rgba(255, 255, 255, 0.08);
  background-color: #0ab041;
}

.icon::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: var(--icon-tree);
  background-size: 1.5rem;
  background-repeat: no-repeat;
  background-position: center;
}

.icon.small {
  border-radius: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  box-shadow: 0 1px #0e8e39, 0 1px rgba(0, 0, 0, 0.24),
    inset 0 1px rgba(255, 255, 255, 0.08);
}

.icon.small::before {
  background-size: 0.75rem;
}
</style>
