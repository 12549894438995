<template>
  <ConfettiModal>
    <ConfettiModalContent class="modal-content">
      <div class="icon">
        <img :src="getThemeAsset('share-icon.svg')" />
      </div>
      <div class="title">share this quest</div>
      <div class="card">
        <div class="image">
          <img :src="getThemeAsset('share.jpeg')" />
        </div>
        <div class="content">
          <div class="card-title">
            {{ title }}
          </div>
          <p>
            Teams will be competing in fast-paced, easy-to-learn mini games that
            are meant to flex their trivia-esque knowledge, puzzle-solving
            skills, and giggles.
          </p>
        </div>
      </div>
      <label class="input">
        <input :value="url" readonly @click="selectText" />
        <div class="copy-button icon-button" role="button" @click="copyUrl">
          <img :src="getThemeAsset('copy-icon.svg')" />
          <transition name="fade">
            <div v-if="tooltipVisible" class="indication-wrapper">
              <div class="indication">Copied</div>
            </div>
          </transition>
        </div>
      </label>
      <div class="share-links">
        <a
          :href="facebookLink"
          target="facebookPopup"
          rel="noopener"
          class="icon-button"
          @click.self.prevent="onClickLink"
        >
          <img :src="getThemeAsset('fb-icon.svg')" />
        </a>
        <a
          :href="linkedinLink"
          target="linkedinPopup"
          rel="noopener"
          class="icon-button"
          @click.self.prevent="onClickLink"
        >
          <img :src="getThemeAsset('linkedin-icon.svg')" />
        </a>
        <a
          :href="twitterLink"
          target="twitterPopup"
          rel="noopener"
          class="icon-button"
          @click.self.prevent="onClickLink"
        >
          <img :src="getThemeAsset('twitter-icon.svg')" />
        </a>
        <a
          :href="emailLink"
          target="emailPopup"
          rel="noopener"
          class="icon-button"
          @click.self.prevent="onClickLink"
        >
          <img :src="getThemeAsset('email-icon.svg')" />
        </a>
      </div>
    </ConfettiModalContent>
  </ConfettiModal>
</template>

<script lang="ts">
import { useClipboard } from '@vueuse/core'
import { computed, defineComponent, ref } from 'vue'
import { delay } from '../utils/delay'
import { useTheme } from '../utils/useTheme'
import { ConfettiModal } from './ConfettiModal.vue'
import { ConfettiModalContent } from './ConfettiModalContent.vue'
const SHARE_URL = 'https://withconfetti.com/product/virtual-mini-games'

export const ConfettiShareModal = defineComponent({
  props: {
    scoreItems: {
      type: Number,
      required: true,
    },
  },
  components: {
    ConfettiModal,
    ConfettiModalContent,
  },
  setup(props) {
    const { getThemeAsset, themeScoreType } = useTheme()
    const { copy } = useClipboard()
    const title = 'Virtual Mini Games'

    const tooltipVisible = ref(false)
    const copyUrl = async () => {
      copy(SHARE_URL)
      tooltipVisible.value = true
      await delay(1500)
      tooltipVisible.value = false
    }

    const selectText = ({ target }: FocusEvent) =>
      (target as HTMLInputElement).select()

    const text = computed(() => {
      if (!themeScoreType.value) return ''
      return `I just ${themeScoreType.value.actionPast} ${
        props.scoreItems > 1 ? props.scoreItems : 'a'
      } ${themeScoreType.value.getTitle(
        props.scoreItems,
      )}! 💪 Spice up your days with mini games that give back 🌍. Share with me how many you've ${
        themeScoreType.value.actionPast
      }! 🌳`
    })

    const twitterLink = computed(() => {
      if (!text.value) return
      const params = new URLSearchParams({
        text: text.value,
        url: SHARE_URL,
        via: 'withconfetti',
      })
      return `https://twitter.com/intent/tweet?${params}`
    })

    const linkedinLink = computed(() => {
      if (!text.value) return
      const params = new URLSearchParams({
        mini: 'true',
        source: 'Confetti',
        title,
        summary: text.value,
        url: SHARE_URL,
      })
      return `https://www.linkedin.com/shareArticle?${params}`
    })

    const facebookLink = computed(() => {
      const params = new URLSearchParams({ u: SHARE_URL })
      return `https://www.facebook.com/sharer.php?${params}`
    })

    const emailLink = computed(() => {
      if (!text.value) return
      const body = window.encodeURIComponent(`${text.value} ${SHARE_URL}`)
      return `mailto:?body=${body}`
    })

    const onClickLink = ({ target }: Event) => {
      const link = target as HTMLAnchorElement
      const height = 540
      const width = 720
      const top = Math.round(window.screen.height / 2 - height / 2)
      const left = Math.round(window.screen.width / 2 - width / 2)
      window.open(
        link.href,
        link.target,
        `scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=${width},height=${height},top=${top},left=${left}`,
      )
    }

    return {
      url: SHARE_URL,
      title,
      copyUrl,
      tooltipVisible,
      selectText,
      linkedinLink,
      facebookLink,
      emailLink,
      twitterLink,
      onClickLink,
      getThemeAsset,
    }
  },
})

export default ConfettiShareModal
</script>

<style scoped>
.modal-content {
  width: 28rem;
}

.title {
  color: rgb(255, 136, 0);
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 1.5rem;
  text-transform: uppercase;
  margin-top: 1rem;
}

.card {
  border-radius: 1.5rem;
  box-shadow: var(--interactive-strong-base-shadow-resting);
  display: flex;
  margin-top: 0.75rem;
  padding: 0.5rem;
}

.image {
  border-radius: 1rem;
  box-shadow: var(--interactive-strong-base-shadow-resting);
  flex: 0 0 4rem;
  margin-right: 1rem;
  overflow: hidden;
}

.content {
  padding: 0.5rem 0.5rem 0.5rem 0;
  text-align: left;
}

.card-title {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

p {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 0.75rem;
  -webkit-line-clamp: 3;
  line-height: 1rem;
  margin-top: 0.25rem;
  opacity: 0.64;
  overflow: hidden;
}

.icon {
  align-items: center;
  border: solid 0.125rem rgba(var(--base-color), 0.16);
  border-radius: 1.5rem;
  display: flex;
  height: 4rem;
  justify-content: center;
  margin: 0 auto;
  width: 4rem;
}

.icon img {
  height: 1.75rem;
  width: 1.75rem;
}

.input {
  border: 0.125rem solid var(--base-shadow-xs-color);
  border-radius: 1.5rem;
  display: flex;
  height: 3.5rem;
  margin-top: 1rem;
}

input {
  flex: 1;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding-left: 1rem;
}

.copy-button {
  align-items: center;
  background-color: rgba(var(--base-color), 0.04);
  border-radius: 1.25rem;
  display: flex;
  height: 3rem;
  justify-content: center;
  margin: 0.125rem;
  position: relative;
  width: 3rem;
}

.copy-button::before {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
  bottom: 0;
  content: '';
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translateX(-100%);
  width: 4rem;
}

.copy-button img {
  height: 1.25rem;
  width: 1.25rem;
}

.indication-wrapper {
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: var(--elevation-m) rgba(var(--green-color), 0.32);
  margin-top: 0.5rem;
  padding: 0.25rem;
  position: absolute;
  top: 100%;
}

.indication {
  background: rgba(var(--green-color), 0.16);
  border-radius: 0.5rem;
  color: RGB(var(--green-color));
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  padding: 0.25rem 1rem;
}

.share-links {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.share-links a {
  align-items: center;
  background-color: rgba(var(--base-color), 0.04);
  border-radius: 1.25rem;
  display: flex;
  height: 3rem;
  justify-content: center;
  width: 4.75rem;
}

.share-links img {
  color: RGB(var(--base-color));
  height: 1.25rem;
  width: 1.25rem;
}

.share-links a + a {
  margin-left: 1rem;
}

.icon-button img {
  pointer-events: none;
  transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.icon-button:hover img {
  transform: scale(1.1);
}

.icon-button:active img {
  transform: scale(1.05);
}

.modal-enter .modal-content,
.modal-leave-active .modal-content {
  opacity: 0;
  transform: translateY(2rem);
}
</style>
