import { mean, median, standardDeviation } from 'simple-statistics'
import { computed, ref } from 'vue'
import { getTimeOffset } from './api'
import { delay } from './delay'

const offsets = ref<number[]>([])

const offset = computed(() => {
  if (!offsets.value.length) return 0
  const limit = median(offsets.value) + standardDeviation(offsets.value)
  const values = offsets.value.filter((value) => limit > value)
  return values.length ? Math.floor(mean(values)) : 0
})

const measureTimeOffset = async (i = 3) => {
  while (i--) {
    const value = await getTimeOffset()
    offsets.value.push(value)
    if (i > 0) await delay(1000)
  }
  return offset.value
}

export const useTimeOffset = () => ({ offset, measureTimeOffset })
