<template>
  <div :class="$style.container">
    <transition-group name="fade" appear>
      <div
        v-for="({ place, team, players, coins, scoreItems }, i) in rows"
        :key="i"
        :class="$style.box"
        :style="getThemeImageStyle('wood-light-top.svg')"
      >
        <div :class="$style.trophy">
          <div :class="$style.place">
            {{ place }}
          </div>
        </div>

        <div :class="$style.teamNameContainer">
          <div :class="$style.teamName">{{ team.emoji }} {{ team.name }}</div>
        </div>

        <ConfettiPlayersListVertical
          :players="players"
          :class="$style.players"
        />

        <ConfettiCompetitionScore
          v-bind="{ coins, scoreItems }"
          :class="$style.score"
        />
      </div>
      <div v-for="x in 3 - rows.length" :key="x" :class="$style.box">
        &nbsp;
      </div>
    </transition-group>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { Competition } from '../../types'
import { ConfettiCompetitionScore } from '../components/ConfettiCompetitionScore.vue'
import { ConfettiPlayersListVertical } from '../components/ConfettiPlayersListVertical.vue'
import { useTheme } from '../utils/useTheme'

export const ConfettiTrophyBox = defineComponent({
  components: {
    ConfettiCompetitionScore,
    ConfettiPlayersListVertical,
  },
  props: {
    competition: {
      required: true,
      type: Object as PropType<Competition>,
    },
  },
  setup(props) {
    const rows = computed(() => props.competition.finishedGames.slice(0, 3))
    const { getThemeImageStyle } = useTheme()

    return {
      rows,
      getThemeImageStyle,
    }
  },
})

export default ConfettiTrophyBox
</script>

<style module>
.container {
  display: flex;
  justify-content: center;
}

.box {
  flex: 0 0 auto;
  background-repeat: no-repeat;
  width: 24rem;
  height: 18.25rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box:nth-child(1) {
  margin: -2rem -6rem 0;
  z-index: 1;
}

.box:nth-child(1),
.box:nth-child(3) {
  order: 1;
}

.trophy {
  position: relative;
  margin-top: -1.25rem;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  box-shadow: 0 0.125rem #543200, inset 0 0.25rem rgba(255, 255, 255, 0.16);
}

.trophy .place {
  font-size: 1.5rem;
  font-weight: 800;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 50%;
  top: 0.25rem;
  bottom: 0.25rem;
  left: 0.25rem;
  right: 0.25rem;
}

.box:nth-child(1) .trophy {
  background-image: linear-gradient(135deg, #ffc670, #f59300);
}

.box:nth-child(1) .trophy .place {
  background-image: linear-gradient(315deg, #ffce85, #f59300);
}

.box:nth-child(2) .trophy {
  background-image: linear-gradient(135deg, #ddd7e0, #8b838f);
}

.box:nth-child(2) .trophy .place {
  background-image: linear-gradient(315deg, #ddd7e0, #8b838f);
}

.box:nth-child(3) .trophy {
  background-image: linear-gradient(135deg, #e0b599, #8f5128);
}

.box:nth-child(3) .trophy .place {
  background-image: linear-gradient(315deg, #e0b599, #8f5128);
}

.teamNameContainer {
  margin-top: 1rem;
  height: 3rem;
  border-radius: 1.25rem;
  box-shadow: 0 1px #071014, inset 0 2px rgba(255, 255, 255, 0.32);
  background-image: linear-gradient(to bottom, #ade4ff, #5cc9ff);
}

.teamName {
  border-radius: 1.125rem;
  box-shadow: inset 0 0.125rem #5dc9ff;
  background-color: #fff;
  margin: 0.125rem;
  height: 2.75rem;
  padding: 0.375rem 0.875rem 0.375rem 0.625rem;
  display: flex;
  align-items: center;
  font-family: Skranji;
  font-size: 1.25rem;
  color: #003652;
}

.players {
  margin-bottom: 1rem;
  max-width: 16rem;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}

.score {
  width: 6rem;
}
</style>

<style scoped>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(2rem);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-in,
    transform 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.fade-enter-active:nth-child(1) {
  transition-delay: 0.1s;
}

.fade-enter-active:nth-child(2) {
  transition-delay: 0.2s;
}

.fade-enter-active:nth-child(3) {
  transition-delay: 0.3s;
}
</style>
