<template>
  <ConfettiForestBackground>
    <transition name="page" mode="out-in">
      <main v-if="isLoading" />
      <ConfettiGameScreen v-else-if="isGameLoaded" />
      <ConfettiLoginScreen v-else>
        <ConfettiLoginForm />
      </ConfettiLoginScreen>
    </transition>
  </ConfettiForestBackground>
</template>

<script lang="ts">
import * as FullStory from '@fullstory/browser'
import * as Sentry from '@sentry/browser'
import { AxiosError } from 'axios'
import { computed, defineComponent, onBeforeMount, watch } from 'vue'
import { useRoute } from 'vue-router'
import { ConfettiForestBackground } from '../components/ConfettiForestBackground.vue'
import { ConfettiGameScreen } from '../components/ConfettiGameScreen.vue'
import { ConfettiLoginForm } from '../components/ConfettiLoginForm.vue'
import { ConfettiLoginScreen } from '../components/ConfettiLoginScreen.vue'
import { getGame, getGameTheme } from '../utils/api'
import { useGame } from '../utils/useGame'
import { useLoading } from '../utils/useLoading'
import { useTheme } from '../utils/useTheme'
import { useTimeOffset } from '../utils/useTimeOffset'

export default defineComponent({
  components: {
    ConfettiLoginForm,
    ConfettiForestBackground,
    ConfettiGameScreen,
    ConfettiLoginScreen,
  },
  setup() {
    const route = useRoute()
    const code = route.params.code as string

    const { setTheme } = useTheme()

    const { setGame, isGameLoaded, player, playerId, isPendingSubscribe } =
      useGame(code)
    const { measureTimeOffset } = useTimeOffset()

    const [loadGame, isLoadingGame] = useLoading(async () => {
      try {
        await Promise.all([
          getGameTheme(code).then(setTheme),
          getGame(code).then(setGame),
        ])
      } catch (err) {
        // ignore auth errors
        const statusCode = (err as AxiosError).request?.status
        if (statusCode === 401 || statusCode === 403) return
        throw err
      }
    })

    const isLoading = computed(
      () => isLoadingGame.value || isPendingSubscribe.value,
    )

    onBeforeMount(loadGame)

    onBeforeMount(async () => {
      const offset = await measureTimeOffset()
      Sentry.setExtra('offset', offset)
      if (offset >= 5000)
        Sentry.captureException(new Error('Time offset too long'))
    })

    onBeforeMount(() => {
      watch(
        playerId,
        (id) => {
          if (!id) return
          Sentry.setUser({ id })
          FullStory.identify(id)
        },
        { immediate: true },
      )

      watch(
        () => player.value?.name,
        (name) => {
          if (!name) return
          Sentry.setUser({ username: name })
          FullStory.setUserVars({ displayName: name })
        },
        { immediate: true },
      )
    })

    return {
      isGameLoaded,
      isLoading,
      code,
    }
  },
})
</script>
