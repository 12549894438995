import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { useTimeOffset } from './useTimeOffset'

const TIMER_INTERVAL = 32

const padDigits = (n: number) => {
  const d = n.toString().slice(0, 2)
  return (
    Array(2 - d.length)
      .fill('0')
      .join('') + d
  )
}

export const useTimer = () => {
  const now = ref(0)
  const startDate = ref(0)
  const duration = ref(0)
  const interval = ref<number>()

  const { offset } = useTimeOffset()

  const startTimer = (initial: number, seconds: number, offsetNow: boolean) => {
    const getNow = () => Date.now() - (offsetNow ? offset.value : 0)
    now.value = getNow()
    startDate.value = initial
    duration.value = seconds

    if (isTimeOut.value) return

    interval.value = window.setInterval(() => {
      now.value = getNow()
    }, TIMER_INTERVAL)
  }

  const stopTimer = () => {
    window.clearInterval(interval.value)
    interval.value = undefined
  }

  const resetTimer = () => {
    startDate.value = 0
    now.value = 0
  }

  const timeLeft = computed(() => {
    const diff = Math.max(now.value - startDate.value, 0)
    const miliseconds = duration.value * 1000
    return Math.max(miliseconds - diff, 0)
  })

  const timer = computed(() => {
    const seconds = Math.floor(timeLeft.value / 1000)
    const miliseconds = timeLeft.value % 1000
    return [seconds, miliseconds].map(padDigits).join(':')
  })

  const isTimeOut = computed(() => timeLeft.value === 0)

  const isTimerActive = computed(() => Boolean(interval.value))

  onBeforeUnmount(stopTimer)

  watch(isTimeOut, (shouldStop) => {
    if (shouldStop) stopTimer()
  })

  return {
    timer,
    isTimeOut,
    isTimerActive,
    startTimer,
    resetTimer,
  }
}
