<template>
  <div :class="$style.wrapper">
    <svg viewBox="0 0 1 1" />
    <div :class="$style.loader">
      <div :class="$style.ring" />
      <div :class="$style.ring" />
      <div :class="$style.ring" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const ConfettiLoader = defineComponent({})

export default ConfettiLoader
</script>

<style module>
@keyframes rotateOpacity {
  0% {
    opacity: 0.1;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    opacity: 1;
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.wrapper {
  position: relative;
}

.wrapper svg {
  height: 100%;
}

.loader {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.ring {
  border: 3px solid transparent;
  border-left: 3px solid currentColor;
  border-radius: 50%;
  box-sizing: border-box;
  height: 100%;
  position: absolute;
  transition: all 0.3s ease;
  width: 100%;
}

.ring:nth-child(1) {
  animation: rotate 1s ease infinite;
}

.ring:nth-child(2) {
  animation: rotateOpacity 1s ease infinite 0.1s;
}

.ring:nth-child(3) {
  animation: rotateOpacity 1s ease infinite 0.2s;
}
</style>
