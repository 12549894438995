<template>
  <label
    v-bind="disabled && { disabled: 'disabled' }"
    :class="[$style.button, { [$style.loading]: loading }]"
    role="button"
    :style="{
      '--button-top-left': getThemeImageUrl('button-top-left.svg'),
      '--button-bottom-right': getThemeImageUrl('button-bottom-right.svg'),
    }"
  >
    <transition name="fade" appear>
      <ConfettiLoader v-if="loading" :class="$style.loader" />
    </transition>
    <div :class="$style.slot">
      <slot />
    </div>
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ConfettiLoader } from '../components/ConfettiLoader.vue'
import { useTheme } from '../utils/useTheme'

export const ConfettiButton = defineComponent({
  props: {
    disabled: Boolean,
    loading: Boolean,
  },
  components: {
    ConfettiLoader,
  },
  setup() {
    const { getThemeImageUrl } = useTheme()

    return {
      getThemeImageUrl,
    }
  },
})

export default ConfettiButton
</script>

<style module>
.button {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 1.5rem;
  line-height: 1.5rem;
  font-size: 1.25rem;
  font-weight: 800;
  color: #fff;
  transition: 0.2s ease;
  /* fixes transition */
  will-change: box-shadow, transform;
  background-color: var(--color);
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.12),
    rgba(255, 255, 255, 0)
  );
  box-shadow: 0 0.25rem rgba(0, 0, 0, 0.24), 0 1rem 2rem -1rem var(--color),
    0 0.25rem var(--color), inset 0 0.25rem rgba(255, 255, 255, 0.12);
}

.button::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: var(--button-top-left);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: auto 3.5rem;
}

.button::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: var(--button-bottom-right);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: auto 3.5rem;
}

.button.loading,
.button[disabled] {
  pointer-events: none;
}

.button[disabled] {
  color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 0.125rem rgba(0, 0, 0, 0.24), 0 1rem 2rem -1rem var(--color),
    0 0.125rem var(--color), inset 0 0.25rem rgba(255, 255, 255, 0.12);
}

.button:hover {
  transform: translateY(-0.125rem);
  box-shadow: 0 0.375rem rgba(0, 0, 0, 0.24), 0 1rem 2rem -1rem var(--color),
    0 0.375rem var(--color), inset 0 0.5rem 2rem rgba(255, 255, 255, 0.24);
}

.button:active {
  transform: translateY(-1px);
  box-shadow: 0 0.3125rem rgba(0, 0, 0, 0.24), 0 1rem 2rem -1rem var(--color),
    0 0.3125rem var(--color), inset 0 0.25rem 0.5rem rgba(255, 255, 255, 0.12);
}

.loader {
  position: absolute !important;
  height: 1.25rem;
}

.slot {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.25s ease-out;
}

.loader + .slot {
  opacity: 0;
}
</style>
