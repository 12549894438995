import Bowser from 'bowser'

const browser = Bowser.getParser(window.navigator.userAgent)
export const isValidBrowser =
  browser.satisfies({
    chrome: '>0',
    // first Chromium-based version
    edge: '>=79',
    macos: {
      safari: '>0',
    },
  }) || false
