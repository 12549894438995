<template>
  <div :class="$style.score">
    <ConfettiCoinIcon :class="$style.icon" small />
    <span :class="$style.scoreText">{{ coins }}</span>
    <ConfettiTreeIcon :class="[$style.icon, $style.treeIcon]" small />
    <span :class="$style.scoreText">{{ scoreItems }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ConfettiTreeIcon } from '../components/ConfettiTreeIcon.vue'
import { ConfettiCoinIcon } from '../components/ConfettiCoinIcon.vue'

export const ConfettiCompetitionScore = defineComponent({
  components: {
    ConfettiTreeIcon,
    ConfettiCoinIcon,
  },
  props: {
    coins: {
      type: Number,
      default: 0,
    },
    scoreItems: {
      type: Number,
      default: 0,
    },
  },
})

export default ConfettiCompetitionScore
</script>

<style module>
.score {
  display: flex;
  align-items: center;
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: 800;
  color: #003652;
}

.icon {
  flex: 0 0 auto;
}

.treeIcon {
  margin-left: auto;
}

.scoreText {
  margin-top: 0.125rem;
  margin-left: 0.25rem;
}
</style>
