<template>
  <transition name="modal" @after-enter="open">
    <div
      role="dialog"
      class="modal"
      tabindex="-1"
      :style="{ '--overlay-color': themeConfig?.overlayColor }"
      @click.self="close"
      @keydown.esc="close"
    >
      <div class="scroll-container">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance } from 'vue'
import { useTheme } from '../utils/useTheme'

export const ConfettiModal = defineComponent({
  head: {
    htmlAttrs: {
      style: 'overflow: hidden;',
    },
  },
  setup(_, { emit }) {
    const { themeConfig } = useTheme()
    const instance = getCurrentInstance()
    const close = () => emit('close')
    const open = () => {
      ;(instance?.vnode.el as HTMLElement).focus()
      emit('open')
    }

    return { close, open, themeConfig }
  },
})

export default ConfettiModal
</script>

<style scoped>
.modal {
  align-items: center;
  background-color: rgba(var(--overlay-color), 0.88);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  transition: background-color 0.15s ease;
  z-index: 10;
}

.scroll-container {
  max-height: 100%;
}

.modal:focus {
  outline: none;
}

.modal.modal-enter-active,
.modal.modal-leave-active {
  transition: background-color 0.5s ease;
}

.modal.modal-enter,
.modal.modal-leave-to {
  background-color: transparent;
}
</style>
