<template>
  <main :class="$style.intro">
    <div
      :class="$style.wrapper"
      class="wrapper"
      :style="getThemeImageStyle('wood-intro.svg')"
    >
      <div :class="$style.content">
        <h2 :class="$style.title">Introduction</h2>
        <p>
          You and your team will be presented with a series of 8 mini games,
          each has 8 questions, aimed to bring you a smile, while leaving this
          world a better place!
        </p>
        <img v-if="introImage" :class="$style.image" :src="introImage" />
        <p>You will have 45 seconds to answer each question.</p>
        <p v-if="introText">
          {{ introText }}
        </p>
        <p>
          Every game has a different set of rules, make sure to read the
          introduction and example of each game before starting.
        </p>
        <p>
          You all have the opportunity to try and answer the questions, and
          there is an unlimited amount of attempts, so feel free to try.
        </p>
        <p>
          We recommend waiting for everyone before starting the game, but late
          comers can still join mid-game. Players can leave at any time.
        </p>
        <p>
          If something isn't working as expected, try refreshing your browsers.
          Otherwise ask your Confetti facilitator for help.
        </p>
        <p>
          We hope this game sparks fun, laughter, and hope for a better future
          🌎.
        </p>
      </div>

      <ConfettiGameFrame :class="$style.yourTeam" class="frame">
        <div
          :class="$style.yourTeamContent"
          :style="getThemeImageStyle('wood-team.svg')"
        >
          <ConfettiPlayersList
            :class="$style.yourTeamList"
            v-bind="{ players, team }"
          />

          <div v-if="isGameFull" :class="$style.waiting">Game is full</div>

          <div v-else-if="isPlayerReady" :class="$style.waiting">
            Waiting for other players...
          </div>

          <template v-else>
            <ConfettiCheckbox
              v-if="!isGameOngoing"
              v-model="isReadyToggle"
              :disabled="isLoading"
              :class="$style.isReadyCheckbox"
            >
              Team gathered and ready!
            </ConfettiCheckbox>

            <ConfettiPrimaryButton
              :class="$style.isReadyButton"
              :disabled="!isReadyToggle && !isGameOngoing"
              :loading="isLoading"
              @click="onClickReady"
            >
              Enter Game
            </ConfettiPrimaryButton>
          </template>
        </div>
      </ConfettiGameFrame>
    </div>
  </main>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { ConfettiCheckbox } from '../components/ConfettiCheckbox.vue'
import { ConfettiGameFrame } from '../components/ConfettiGameFrame.vue'
import { ConfettiPlayersList } from '../components/ConfettiPlayersList.vue'
import { ConfettiPrimaryButton } from '../components/ConfettiPrimaryButton.vue'
import { setPlayerReady } from '../utils/api'
import { useGame } from '../utils/useGame'
import { useLoading } from '../utils/useLoading'
import { useTheme } from '../utils/useTheme'

export const ConfettiGameIntro = defineComponent({
  components: {
    ConfettiCheckbox,
    ConfettiPrimaryButton,
    ConfettiGameFrame,
    ConfettiPlayersList,
  },
  setup() {
    const route = useRoute()
    const code = route.params.code as string
    const isReadyToggle = ref(false)

    const { game, setGame, players, isPlayerReady, isGameOngoing, isGameFull } =
      useGame(code)
    const team = computed(() => game.value?.team)

    const [onClickReady, isLoading] = useLoading(() =>
      setPlayerReady(code).then(setGame),
    )

    const { getThemeImageStyle, getThemeAsset, themeScoreType } = useTheme()

    const introText = computed(() => {
      if (!game.value) return
      return game.value.introText ?? themeScoreType.value?.introText
    })

    const introImage = computed(() => {
      if (!game.value) return
      return game.value.introImage ?? getThemeAsset('intro-description.png')
    })

    return {
      team,
      players,
      isReadyToggle,
      onClickReady,
      isPlayerReady,
      isGameFull,
      isLoading,
      isGameOngoing,
      getThemeImageStyle,
      getThemeAsset,
      introText,
      introImage,
    }
  },
})

export default ConfettiGameIntro
</script>

<style module>
.intro {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.wrapper {
  height: 46rem;
  width: 58rem;
  padding: 6rem 6rem 0;
  position: relative;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
}

.image {
  margin-top: 1rem;
  border-radius: 1.5rem;
  height: 12rem;
}

.content {
  overflow: auto;
  height: 100%;
  padding: 3rem 18rem 12rem 4rem;
  color: #fff;
}

.title {
  line-height: 3rem;
  font-size: 3rem;
  font-weight: 800;
}

.content p {
  margin-top: 1rem;
  line-height: 1.5rem;
  font-size: 1.25rem;
}

.yourTeam {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 6rem;
  margin-top: 1rem;
  width: 22rem;
  height: 33rem;
}

.yourTeamContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
  background-repeat: no-repeat;
}

.yourTeamList {
  height: 20rem;
}

.isReadyButton,
.isReadyCheckbox {
  margin-top: 1rem;
}

.waiting {
  margin: 0 auto;
  margin-top: 4rem;
  padding: 0.5rem 1rem;
  border-radius: 0.75rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  color: rgba(0, 0, 0, 0.64);
  background-color: rgba(0, 0, 0, 0.08);
}
</style>

<style scoped>
.page-enter-from,
.page-leave-to,
.page-enter-active .frame,
.page-leave-active .frame {
  opacity: 0;
  transform: translateY(2rem);
}

.page-enter-active,
.frame {
  transition: opacity 0.25s ease-in,
    transform 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.page-leave-active {
  transition: opacity 0.25s ease-out,
    transform 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
</style>
