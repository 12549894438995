<template>
  <div
    :class="[$style.icon, { [$style.small]: small }]"
    :style="{
      backgroundImage: `url(${getThemeAsset(
        'icon-star.svg',
      )}), linear-gradient(315deg, #ffc670, #f59300)`,
    }"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useTheme } from '../utils/useTheme'

export const ConfettiCoinIcon = defineComponent({
  props: {
    small: Boolean,
  },
  setup() {
    const { getThemeAsset } = useTheme()

    return {
      getThemeAsset,
    }
  },
})

export default ConfettiCoinIcon
</script>

<style module>
.icon {
  position: relative;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  box-shadow: 0 0.125rem #543200, inset 0 0.25rem rgba(255, 255, 255, 0.16);
  background-image: linear-gradient(135deg, #ffc670, #f59300);
}

.icon::before {
  content: '';
  position: absolute;
  border-radius: 50%;
  top: 0.25rem;
  bottom: 0.25rem;
  left: 0.25rem;
  right: 0.25rem;
  background-repeat: no-repeat;
  background-position: center;
}

.icon.small {
  width: 1rem;
  height: 1rem;
  box-shadow: 0 1px #543200, inset 0 0.125rem rgba(255, 255, 255, 0.16);
}

.icon.small::before {
  top: 0.125rem;
  bottom: 0.125rem;
  left: 0.125rem;
  right: 0.125rem;
}
</style>
