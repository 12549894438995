<template>
  <transition name="page" mode="out-in">
    <ConfettiGameOverScreen v-if="isGameOver" />

    <main
      v-else-if="isGameOngoing && isPlayerReady"
      :style="{ '--button-color': buttonColor }"
      :class="$style.gameContainer"
    >
      <transition name="page" mode="out-in">
        <section
          v-if="showRoundIntro"
          :class="$style.roundIntro"
          :style="getThemeImageStyle('wood-preview.svg')"
        >
          <h1>{{ game.currentRound.title }}</h1>
          <p>{{ game.currentRound.description }}</p>
          <ConfettiGameFrame :class="$style.example">
            <div :class="$style.exampleImageFrame">
              <div :class="$style.exampleTitle">example</div>
              <ConfettiInlineSvg
                :class="$style.exampleImage"
                :src="exampleImage"
              />
              <div :class="$style.exampleAnswer">
                {{ game.currentRound.example.answer.text }}
              </div>
            </div>
          </ConfettiGameFrame>
        </section>
        <ConfettiGameFrame v-else big :class="$style.frame">
          <div
            :class="$style.gameName"
            :style="getThemeImageStyle('wood-game-name.svg')"
          >
            {{ game.currentRound.title }}
          </div>

          <transition :name="showAnswer ? 'fade' : 'slide'" mode="out-in">
            <ConfettiInlineSvg :key="svg.url" :content="svg.content" />
          </transition>

          <ConfettiProgressSteps
            :steps-count="questionsCount"
            :active-step="currentQuestionIndex"
            :class="$style.progress"
          />
        </ConfettiGameFrame>
      </transition>

      <transition name="fade" mode="out-in">
        <div v-if="showRoundIntro" :class="$style.bottom">
          <!-- start round -->
          <ConfettiButton
            v-if="currentQuestion"
            :loading="isStarting"
            :class="$style.button"
            @click="start(currentQuestion)"
          >
            Start Playing
          </ConfettiButton>
          <!-- start first round (first question not loaded yet) -->
          <ConfettiButton
            v-else
            :loading="isLoadingNextQuestion"
            :class="$style.button"
            @click="next()"
          >
            Start Playing
          </ConfettiButton>
        </div>
        <div v-else :class="$style.bottom">
          <transition name="fade" mode="out-in">
            <form
              v-if="isQuestionOngoing"
              :class="$style.answerForm"
              @submit.prevent="onSubmitAnswer(currentQuestion, content)"
            >
              <fieldset :disabled="isSendingAnswer">
                <input
                  v-model.trim="content"
                  v-focus
                  required
                  :class="$style.answerInput"
                  placeholder="Type your answer here"
                  title=""
                  @invalid.prevent
                />
                <div
                  :class="[
                    $style.submitContainer,
                    { [$style.wrong]: wrongIndicationVisible },
                  ]"
                >
                  <ConfettiButton
                    :disabled="!content"
                    :loading="isSendingAnswer"
                    :class="[$style.button, $style.answerButton]"
                  >
                    Submit
                    <div :class="$style.timer">
                      {{ timer }}
                    </div>
                    <input type="submit" hidden />
                  </ConfettiButton>
                  <div :class="$style.wrongIndication">Try Again!</div>
                </div>
              </fieldset>
            </form>

            <div
              v-else
              :key="currentQuestionKey"
              :class="[
                $style.nextButtonContainer,
                {
                  [$style.answered]: isQuestionAnswered,
                  [$style.isLoading]: isLoadingNextQuestion,
                },
              ]"
            >
              <div :class="$style.feedback">
                {{ isQuestionAnswered ? 'Good Job!' : "Time's Up" }}
              </div>
              <transition name="fade" mode="out-in">
                <ConfettiButton
                  v-if="isNextQuestionAvailable"
                  :loading="isLoadingNextQuestion"
                  :class="[$style.button, $style.nextButton]"
                  @click="next(currentQuestion)"
                >
                  {{ isLastQuestion ? 'Next Game' : 'Next Question' }}
                </ConfettiButton>
                <ConfettiButton v-else :class="$style.button" @click="finish">
                  Finish Game!
                </ConfettiButton>
              </transition>
            </div>
          </transition>
        </div>
      </transition>
    </main>

    <ConfettiGameIntro v-else />
  </transition>
</template>

<script lang="ts">
import { AxiosError } from 'axios'
import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { GameQuestion, RoundType } from '../../types'
import { ConfettiButton } from '../components/ConfettiButton.vue'
import { ConfettiGameFrame } from '../components/ConfettiGameFrame.vue'
import { ConfettiGameIntro } from '../components/ConfettiGameIntro.vue'
import { ConfettiGameOverScreen } from '../components/ConfettiGameOverScreen.vue'
import {
  ConfettiInlineSvg,
  fetchContent,
} from '../components/ConfettiInlineSvg.vue'
import { ConfettiProgressSteps } from '../components/ConfettiProgressSteps.vue'
import { loadNextQuestion, startQuestion, submitAnswer } from '../utils/api'
import { delay } from '../utils/delay'
import { useGame } from '../utils/useGame'
import { useLoading } from '../utils/useLoading'
import { useTheme } from '../utils/useTheme'
import { useTimer } from '../utils/useTimer'

const BUTTON_COLORS: Record<RoundType, string> = {
  [RoundType.Red]: '#ff6647',
  [RoundType.Orange]: '#eb8039',
  [RoundType.Yellow]: '#ebb726',
  [RoundType.Green]: '#46c246',
  [RoundType.Blue]: '#3cc6d6',
  [RoundType.Indigo]: '#4677ff',
  [RoundType.Purple]: '#875dff',
  [RoundType.Pink]: '#d656cd',
}

const toPathname = (url?: string) => (url ? new URL(url).pathname : undefined)

export const ConfettiGameScreen = defineComponent({
  directives: {
    focus: {
      mounted: (el: HTMLElement) => setTimeout(() => el.focus()),
    },
  },
  components: {
    ConfettiButton,
    ConfettiInlineSvg,
    ConfettiProgressSteps,
    ConfettiGameOverScreen,
    ConfettiGameFrame,
    ConfettiGameIntro,
  },
  setup() {
    const route = useRoute()
    const code = route.params.code as string
    const content = ref('')
    const wrongIndicationVisible = ref<Boolean>(false)

    const {
      game,
      setGame,
      players,
      isPlayerReady,
      isGameOngoing,
      isLastQuestion,
      currentQuestion,
      showRoundIntro,
      isGameOver,
    } = useGame(code)
    const { startTimer, resetTimer, timer, isTimeOut } = useTimer()

    const isNextQuestionAvailable = computed(
      () => !(isLastQuestion.value && game.value?.currentRound.isLastRound),
    )

    const buttonColor = computed(() => {
      if (!game.value) return
      return BUTTON_COLORS[game.value.currentRound.type]
    })

    const currentState = computed(() => {
      if (!game.value?.currentQuestion) return
      const round = game.value.currentRound
      const question = game.value.currentQuestion
      return { round, question }
    })

    const questionsCount = computed(
      () => game.value?.currentRound.questionKeys.length,
    )
    const currentQuestionIndex = computed(() => {
      if (!currentState.value) return
      const { round, question } = currentState.value
      return round.questionKeys.indexOf(question._key)
    })

    const currentQuestionKey = computed(() => currentQuestion.value?._key)

    const [start, isStarting] = useLoading(async (question: GameQuestion) => {
      await startQuestion(code, question._key).then(setGame)
    })

    const [next, isLoadingNextQuestion] = useLoading(
      async (question?: GameQuestion) => {
        await loadNextQuestion(code, question?._key).then(setGame)
      },
    )

    const [onSubmitAnswer, isSendingAnswer] = useLoading(
      async (question: GameQuestion, value: string) => {
        try {
          await submitAnswer(code, question._key, value).then(setGame)
          content.value = ''
        } catch (err) {
          const statusCode = (err as AxiosError).request?.status
          if (statusCode === 400) return showWrongIndication()
          throw err
        }
      },
    )

    // start and stop timer
    onBeforeMount(() => {
      watch(
        currentState,
        (current, previous) => {
          if (!current) return
          const { round, question } = current

          if (question.answeredAt) {
            resetTimer()
            isSendingAnswer.value = false
            return
          }

          const isSameQuestion = question._key === previous?.question?._key
          const isPreviouslyStarted =
            isSameQuestion && Boolean(previous?.question.startedAt)
          if (!question.startedAt || isPreviouslyStarted) return

          const date = question.offsetStartedAt
            ? Date.now()
            : new Date(question.startedAt).getTime()
          startTimer(date, round.duration, !question.offsetStartedAt)
          content.value = ''
        },
        { immediate: true },
      )

      watch(
        isNextQuestionAvailable,
        (value) => {
          if (!value && isQuestionEnded.value) finish()
        },
        { immediate: true },
      )
    })

    const isQuestionStarted = computed(() =>
      Boolean(currentQuestion.value?.startedAt),
    )
    const isQuestionAnswered = computed(() =>
      Boolean(currentQuestion.value?.answeredAt),
    )
    const isQuestionEnded = computed(
      () => isTimeOut.value || isQuestionAnswered.value,
    )
    const isQuestionOngoing = computed(
      () => isQuestionStarted.value && !isQuestionEnded.value,
    )
    const isQuestionPending = computed(
      () => currentQuestion.value && !isQuestionStarted.value,
    )
    const showAnswer = computed(
      () => isQuestionStarted.value && isQuestionEnded.value,
    )

    const currentQuestionImage = computed(() =>
      toPathname(
        showAnswer.value
          ? game.value?.lastAnswer?.image
          : game.value?.currentQuestion?.image,
      ),
    )
    const exampleImage = computed(() =>
      toPathname(game.value?.currentRound.example.image),
    )

    // preload svg
    const svg = ref<{ url?: string; content?: string }>({})
    watch(
      currentQuestionImage,
      async (url) => {
        if (!url) return
        const content = await fetchContent(url)
        if (currentQuestionImage.value !== url) return
        svg.value = { url, content }
      },
      { immediate: true },
    )

    const finish = () => {
      isGameOver.value = true
    }

    const showWrongIndication = async () => {
      wrongIndicationVisible.value = true
      await delay(2000)
      wrongIndicationVisible.value = false
    }

    const { getThemeImageStyle } = useTheme()

    return {
      game,
      players,
      content,
      showAnswer,
      svg,
      exampleImage,
      isStarting,
      isLoadingNextQuestion,
      isSendingAnswer,
      isGameOver,
      onSubmitAnswer,
      timer,
      isLastQuestion,
      isQuestionStarted,
      isQuestionOngoing,
      isQuestionPending,
      isQuestionAnswered,
      isNextQuestionAvailable,
      start,
      next,
      finish,
      isGameOngoing,
      showRoundIntro,
      wrongIndicationVisible,
      buttonColor,
      code,
      questionsCount,
      currentQuestion,
      currentQuestionKey,
      currentQuestionIndex,
      isPlayerReady,
      getThemeImageStyle,
    }
  },
})

export default ConfettiGameScreen
</script>

<style module>
.gameContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-height: 100vh;
  position: relative;
  z-index: 1;
}

.roundIntro {
  display: flex;
  flex-direction: column;
  margin: 2rem auto 0;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  width: 58rem;
  height: 41rem;
  padding: 9rem 24rem 9rem 10rem;
  color: #fff;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 5rem;
}

.roundIntro h1 {
  line-height: 5rem;
  text-shadow: 0 0.25rem 0.5rem rgba(255, 255, 255, 0.16);
  font-size: 5rem;
  font-weight: 800;
  margin: 0;
}

.roundIntro p {
  overflow: auto;
  margin-top: 1rem;
  line-height: 2rem;
  font-size: 1.5rem;
}

.example {
  position: absolute;
  top: 7rem;
  right: 0;
  width: 22rem;
  height: 27rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(54, 28, 6, 0.48);
  text-align: center;
}

.exampleImageFrame {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.exampleImage {
  width: 22rem;
  margin: -2rem 0;
}

.exampleTitle {
  line-height: 1.5rem;
  font-size: 0.75rem;
  font-weight: 800;
  letter-spacing: 0.25rem;
  text-indent: 0.25rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.48);
}

.exampleAnswer {
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.48);
}

.frame {
  position: relative;
  z-index: 1;
  height: 33rem;
  width: 50rem;
  padding: 2rem;
}

.frame svg {
  width: 33rem;
  height: auto;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
}

.progress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: var(--button-color);
}

.gameName {
  background-size: contain;
  height: 7.5rem;
  width: 17rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 800;
  color: white;
  position: absolute;
  top: -5.5rem;
  left: 0;
  right: 0;
  margin: auto;
}

.bottom {
  z-index: 1;
  max-width: 50rem;
  width: 100%;
  margin: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.answerForm {
  display: flex;
  flex: 1;
}

.answerForm fieldset {
  display: contents;
  border: none;
  padding: 0;
}

.answerInput {
  color: #fff;
  line-height: 4rem;
  font-size: 1.25rem;
  font-weight: 600;
  height: 4rem;
  padding: 0 1.5rem;
  border-radius: 1.5rem;
  background-color: rgba(255, 255, 255, 0.16);
  border: solid 0.125rem rgba(255, 255, 255, 0);
  flex: 1;
  margin-right: 1rem;
  transition: 0.2s ease;
}

.answerInput:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.answerInput:valid {
  background-color: rgba(255, 255, 255, 0.16);
}

.answerInput:focus {
  border-color: #fff;
  background-color: rgba(255, 255, 255, 0);
}

.button {
  --color: var(--button-color);
  height: 4rem;
  padding: 0 2rem;
}

.wrongIndication {
  pointer-events: none;
  font-size: 1.25rem;
  font-weight: 800;
  color: rgb(var(--error-color));
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
}

.submitContainer {
  position: relative;
}

.submitContainer.wrong .wrongIndication {
  opacity: 1;
}

.submitContainer.wrong .button {
  opacity: 0;
}

.answerButton {
  padding-right: 1.25rem;
}

.nextButtonContainer {
  color: rgb(var(--error-color));
  background-color: rgba(var(--error-color), 0.16);
  border-radius: 2rem;
  padding: 0.75rem 2rem;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 800;
  margin: -0.75rem 0;
}

.nextButtonContainer,
.feedback {
  transition: 0.2s ease;
}

.nextButtonContainer.answered {
  color: rgb(var(--green-color));
  background-color: rgba(var(--green-color), 0.16);
}

.nextButtonContainer .button {
  width: 13rem;
  margin-right: -4rem;
  margin-left: 1rem;
}

.feedback {
  width: 6rem;
}

.nextButtonContainer.isLoading {
  background: none;
}

.nextButtonContainer.isLoading .feedback {
  opacity: 0;
}

.nextButtonContainer.isLoading .button {
  transform: translateX(-5.5rem);
}

.timer {
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.16);
  margin-left: 0.5rem;
  text-align: center;
  width: 3.5rem;
}

.answerButton[disabled] .timer {
  color: white;
}
</style>

<style scoped>
.page-enter-from,
.page-leave-to {
  opacity: 0;
  transform: translateY(2rem);
}

.page-enter-active {
  transition: opacity 0.25s ease-in,
    transform 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.page-leave-active {
  transition: opacity 0.25s ease-out,
    transform 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}

.slide-enter-from {
  transform: translateX(2rem);
}

.slide-leave-to {
  transform: translateX(-2rem);
}

.slide-enter-active {
  transition: 0.25s ease-in;
}

.slide-leave-active {
  transition: 0.25s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  pointer-events: none;
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.25s ease;
}
</style>
