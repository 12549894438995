export type ScoreTypes = 'tree' | 'meal' | 'education'

export type ScoreType = {
  action: string
  getTitle: (scoreItems: number) => string
  actionPast: string
  introText?: string
}

const getTitleFactory = (text: string) => (scoreItems: number) =>
  `${text}${scoreItems > 1 ? 's' : ''}`

export const scoreTypes: Record<ScoreTypes, ScoreType> = {
  tree: {
    action: 'plant',
    actionPast: 'planted',
    introText: `Each right answer will add a coin to your mutual score. For every 16
    coins you win we will plant a REAL tree in the Albertine Rift forests
    of Uganda to restore and conserve nature for biodiversity while also
    supporting local communities and our closest wildlife relatives –
    chimpanzees.`,
    getTitle: getTitleFactory('tree'),
  },
  meal: {
    action: 'donate',
    actionPast: 'donated',
    getTitle: getTitleFactory('meal'),
  },
  education: {
    action: 'fund',
    actionPast: 'funded',
    getTitle: getTitleFactory('educational day'),
  },
}
