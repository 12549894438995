import * as Sentry from '@sentry/browser'
import Pusher, { PresenceChannel } from 'pusher-js'
import { computed, ref } from 'vue'
import { setSocketId } from '../utils/api'
import { appKey, cluster } from './pusherConfig'

export enum ConnectionState {
  initialized = 'initialized',
  connected = 'connected',
  connecting = 'connecting',
  unavailable = 'unavailable',
  failed = 'failed',
  disconnected = 'disconnected',
}

export interface ConnectionStateEvent {
  current: ConnectionState
  previous: ConnectionState
}

const connectionState = ref<ConnectionStateEvent>()
const isUnavailable = computed(
  () => connectionState.value?.current === ConnectionState.unavailable,
)

const subscribe = (code: string) => {
  const instance = Pusher.instances[0] || getPusherInstance()
  return instance.subscribe(`presence-mini-${code}`) as PresenceChannel
}

const getPusherInstance = () => {
  const instance = new Pusher(appKey, {
    cluster,
    authEndpoint: '/api/pusher/auth',
  })

  instance.connection.bind('connected', () => {
    setSocketId(instance.connection.socket_id)
  })

  instance.connection.bind('state_change', (state: ConnectionStateEvent) => {
    connectionState.value = state
  })

  instance.bind(
    'pusher:error',
    ({ code, message }: { code: number | null; message: string }) => {
      const err = new Error(message)
      Sentry.captureException(Object.assign(err, { statusCode: code }))
      console.error(err)
    },
  )

  return instance
}

export const usePusher = () => ({ subscribe, connectionState, isUnavailable })
