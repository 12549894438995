<template>
  <ConfettiButton
    :disabled="disabled"
    :class="$style.button"
    :loading="loading"
  >
    <slot />
  </ConfettiButton>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ConfettiButton } from '../components/ConfettiButton.vue'

export const ConfettiPrimaryButton = defineComponent({
  props: {
    disabled: Boolean,
    loading: Boolean,
  },
  components: {
    ConfettiButton,
  },
})

export default ConfettiPrimaryButton
</script>

<style module>
.button {
  height: 5rem;
  box-shadow: inset 0 0.125rem 0 0 rgba(255, 255, 255, 0.16),
    -0.375rem 0.375rem 2rem 0 rgba(255, 170, 0, 0.16), 0 0.25rem 0 0 #522c00;
  background-image: linear-gradient(to bottom, #ff8800, #ffaa00, #ff9100);
}

.button[disabled] {
  box-shadow: inset 0 0.125rem 0 0 rgba(255, 255, 255, 0.16),
    -0.375rem 0.375rem 2rem 0 rgba(255, 170, 0, 0.16), 0 0.125rem 0 0 #522c00;
}

.button:hover {
  transform: translateY(-0.25rem);
  box-shadow: inset 0 2rem 2rem -1rem rgba(255, 255, 255, 0.32),
    -0.375rem 0.375rem 2rem 0 rgba(255, 170, 0, 0.16), 0 0.5rem 0 0 #522c00;
}

.button:active {
  transform: translateY(-0.125rem);
  box-shadow: inset 0 2rem 2rem -1rem rgba(255, 255, 255, 0.16),
    -0.375rem 0.375rem 2rem 0 rgba(255, 170, 0, 0.16), 0 0.375rem 0 0 #522c00;
}
</style>
