<template>
  <div :class="$style.notice">
    Memorable group experiences with
    <a href="https://www.withconfetti.com" target="_blank" rel="noopener"
      >Confetti</a
    >
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const ConfettiNotice = defineComponent({})

export default ConfettiNotice
</script>

<style module>
.notice {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  opacity: 0.32;
  text-align: center;
}

.notice a {
  text-decoration: underline;
}
</style>
