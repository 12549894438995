import { Ref, ref } from 'vue'

export const useLoading = <T extends any[]>(
  fn: (...args: T) => Promise<any>,
): [invoke: (...args: T) => Promise<void>, isLoading: Ref<boolean>] => {
  const isLoading = ref(false)
  return [
    async (...args: T) => {
      try {
        isLoading.value = true
        await fn(...args)
      } finally {
        isLoading.value = false
      }
    },
    isLoading,
  ]
}
