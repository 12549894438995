<template>
  <div :class="$style.players">
    <div
      v-for="{ name, isYou } in players"
      :key="name"
      :class="[$style.player, { [$style.isYou]: isYou }]"
    >
      {{ isYou ? 'You' : name }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const ConfettiPlayersListVertical = defineComponent({
  props: {
    players: null,
  },
})

export default ConfettiPlayersListVertical
</script>

<style module>
.players {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem 0 auto -0.5rem;
  padding-top: 0.5rem;
}

.player {
  background-color: rgba(0, 54, 82, 0.1);
  font-weight: 700;
  height: 1.5rem;
  border-radius: 0.5rem;
  color: rgba(0, 54, 82, 0.64);
  line-height: 1rem;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  margin: 0.5rem 0 0 0.5rem;
}

.player.isYou {
  color: #fff;
  background-image: linear-gradient(to bottom, #ade4ff, #5cc9ff);
}
</style>
