<template>
  <div :class="$style.list">
    <div :class="$style.title">
      <div v-if="team" :class="$style.team">
        <div :class="$style.teamEmojiContainer">
          <div :class="$style.teamEmoji">
            {{ team.emoji }}
          </div>
        </div>
        <div :class="$style.teamNameContainer">
          <div :class="$style.teamName">
            {{ team.name }}
          </div>
        </div>
      </div>
      <h3 v-else>Your Team</h3>
    </div>

    <ul>
      <li v-for="{ _key, name, emoji, isReady, isYou } in players" :key="_key">
        <div :class="$style.emojiWrapper">
          <div :class="$style.emoji">
            {{ emoji }}
          </div>
        </div>
        <div :class="$style.name">
          {{ name }}
        </div>
        <div v-if="isYou" :class="$style.you">&nbsp;(You)</div>
        <svg
          v-if="isReady"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 96 96"
        >
          <path
            fill-rule="nonzero"
            d="M40 59.51l35.76-35.75a6 6 0 018.49 8.48l-40 40a6 6 0 01-8.5 0l-24-24a6 6 0 018.5-8.48L40 59.5z"
          />
        </svg>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Player } from '../../types'

export const ConfettiPlayersList = defineComponent({
  props: {
    team: null,
    players: {
      required: true,
      type: Array as PropType<Player[]>,
    },
  },
})

export default ConfettiPlayersList
</script>

<style module>
.list {
  overflow: auto;
  padding: 1rem 1rem 1.5rem;
  color: rgb(var(--base-color));
}

.list ul {
  padding: 1rem 0.5rem 0;
  list-style-type: none;
}

.list li {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
}

.list li + li {
  margin-top: 1rem;
}

.list li svg {
  width: 1rem;
  height: 1rem;
  fill: currentColor;
  margin-left: auto;
  flex: 0 0 auto;
}

.emojiWrapper {
  position: relative;
  flex: 0 0 auto;
  background-color: #fff;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  box-shadow: 0 1px #071014, inset 0 2px rgba(255, 255, 255, 0.32);
  background-image: linear-gradient(to bottom, #ade4ff, #5cc9ff);
}

.emojiWrapper::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0.125rem;
  border-radius: 50%;
  box-shadow: inset 0 1px #5dc9ff;
  background-color: #fff;
}

.emoji {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.25rem;
  right: 0;
  bottom: 0;
  left: 0;
}

.name {
  margin-left: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.you {
  opacity: 0.64;
  font-weight: 400;
}

.list h3 {
  line-height: 2rem;
  font-size: 1.5rem;
  font-weight: 800;
}

.list .title::after {
  content: '';
  display: block;
  margin-top: 0.375rem;
  opacity: 0.32;
  background-color: currentColor;
  height: 0.125rem;
  border-radius: 0.125rem;
}

.team {
  display: flex;
  margin-bottom: 0.125rem;
}

.teamEmojiContainer {
  flex: 0 0 auto;
  height: 3rem;
  width: 3rem;
  border-radius: 1.25rem;
  box-shadow: 0 1px #071014, inset 0 2px rgba(255, 255, 255, 0.32);
  background-image: linear-gradient(to bottom, #ade4ff, #5cc9ff);
}

.teamEmoji {
  padding-top: 0.25rem;
  border-radius: 1.125rem;
  box-shadow: inset 0 0.125rem #5dc9ff;
  background-color: #fff;
  margin: 0.125rem;
  height: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}

.teamNameContainer {
  margin-left: 0.5rem;
  white-space: nowrap;
  color: #003652;
}

.teamName {
  line-height: 2rem;
  font-family: Skranji;
  font-size: 1.25rem;
}
</style>
