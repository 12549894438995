<template>
  <transition name="fade" appear>
    <div v-if="rows.length">
      <div
        v-for="{ place, isYourTeam, team, scoreItems, coins, players } in rows"
        :key="place"
        :class="[
          $style.row,
          { [$style.isYourTeam]: isYourTeam },
          { [$style.disable]: disable },
        ]"
        :style="getThemeImageStyle('wood-light-list.svg')"
      >
        <div :class="$style.place">
          <span v-if="!disable"> {{ place }}</span>
          <span v-else>--</span>
        </div>

        <div :class="$style.teamEmojiContainer">
          <div :class="$style.teamEmoji">
            {{ team.emoji }}
          </div>
        </div>

        <div :class="$style.teamNameContainer">
          <div :class="$style.teamName">
            {{ team.name }}
          </div>
          <ConfettiPlayersListVertical
            :players="players"
            :class="$style.players"
          />
        </div>

        <ConfettiCompetitionScore
          v-bind="{ coins, scoreItems }"
          :class="$style.score"
        />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { CompetitionGame } from '../../types'
import { ConfettiCompetitionScore } from '../components/ConfettiCompetitionScore.vue'
import { ConfettiPlayersListVertical } from '../components/ConfettiPlayersListVertical.vue'
import { useTheme } from '../utils/useTheme'

export const ConfettiCompetitionRows = defineComponent({
  components: {
    ConfettiCompetitionScore,
    ConfettiPlayersListVertical,
  },
  props: {
    rows: {
      required: true,
      type: Array as PropType<CompetitionGame[]>,
    },
    disable: Boolean,
  },
  setup() {
    const { getThemeImageStyle } = useTheme()

    return {
      getThemeImageStyle,
    }
  },
})

export default ConfettiCompetitionRows
</script>

<style module>
.row {
  position: relative;
  background-repeat: no-repeat;
  height: 10rem;
  width: 44rem;
  margin: 0 auto;
  padding: 3rem;
  display: flex;
  align-items: center;
}

.row + .row {
  margin-top: -3rem;
}

.row + .row.isYourTeam:last-of-type {
  margin-top: 0;
}

.row .place {
  width: 4rem;
  height: 2rem;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0 auto -4rem;
  color: #fff;
}

.teamEmojiContainer {
  height: 4rem;
  width: 4rem;
  border-radius: 1.5rem;
  box-shadow: 0 1px #071014, inset 0 2px rgba(255, 255, 255, 0.32);
  background-image: linear-gradient(to bottom, #ade4ff, #5cc9ff);
}

.teamEmoji {
  padding-top: 0.25rem;
  border-radius: 1.25rem;
  box-shadow: inset 0 0.125rem #5dc9ff;
  background-color: #fff;
  margin: 0.125rem;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}

.teamNameContainer {
  flex: 1;
  margin-left: 1rem;
  overflow: hidden;
}

.teamName {
  line-height: 2rem;
  font-family: Skranji;
  font-size: 1.25rem;
  color: #003652;
}

.players {
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow: auto;

  /* hide scrollbar */
  padding-bottom: 1rem;
  margin-bottom: -1rem;

  /* scroll fade */
  -webkit-mask: linear-gradient(to right, #000 92%, rgba(0, 0, 0, 0));
}

.players::after {
  content: '';
  display: block;
  flex: 0 0 1.5rem;
}

.score {
  margin-left: 0.5rem;
  width: 5rem;
}

.disable {
  opacity: 0.48;
}
</style>
<style scoped>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(2rem);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-in,
    transform 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition-delay: 0.4s;
}
</style>
