import * as Sentry from '@sentry/browser'
import { memoize, sortBy } from 'lodash-es'
import { Members } from 'pusher-js'
import { computed, ref, watch } from 'vue'
import { Game, GameMetadata, Member, MembersInfo, Player } from '../../types'
import { getGame } from './api'
import { useTheme } from './useTheme'
import { ConnectionState, usePusher } from './usePusher'

interface PusherSubscriptionError {
  err: string
  type: string
  status?: number
}

const getGameMemoized = memoize(
  (code, _revisionId) => getGame(code),
  (_, revisionId) => revisionId,
)

export const useGame = memoize((code: string) => {
  const game = ref<Game>()
  const isGameLoaded = computed(() => Boolean(game.value))
  const channelMembers = ref<MembersInfo>({})
  const playerId = ref<string>()
  const isPendingSubscribe = ref(false)
  const isGameOver = ref(false)

  const { subscribe, connectionState } = usePusher()

  const { themeConfig } = useTheme()

  // subscribe to channel once loaded
  watch(isGameLoaded, (loaded) => {
    if (!loaded) return

    isPendingSubscribe.value = true
    const channel = subscribeChannel()

    channel.bind('pusher:subscription_error', () => {
      isPendingSubscribe.value = false
    })

    channel.bind('pusher:subscription_succeeded', () => {
      isPendingSubscribe.value = false
    })
  })

  // update game once reconnected
  watch(connectionState, (state, previousState) => {
    if (!previousState || state?.current !== ConnectionState.connected) return
    getGame(code).then(setGame)
  })

  const isGameUpdated = (value: GameMetadata, current?: GameMetadata) => {
    if (!current || value._updatedAt > current._updatedAt) return true
    if (value._rev === current._rev || value._updatedAt < current._updatedAt)
      return false

    // game revision changed but updatedAt is the same (issue with Sanity)
    return null
  }

  const setGame = async (value: Game) => {
    const shouldUpdate = isGameUpdated(value, game.value)
    if (shouldUpdate === false) return

    game.value = shouldUpdate ? value : await getGameMemoized(code, value._rev)
  }

  const players = computed<Player[]>(() => {
    if (!game.value) return []

    const playerEmojis = themeConfig.value?.playerEmojis
    if (!playerEmojis) return []

    const { readyPlayers, maxPlayers } = game.value
    const members = sortBy(
      Object.entries(channelMembers.value),
      ([, { ts }]) => ts,
    )
    return members.map(([id, info], i) => {
      const isReady = readyPlayers.includes(id)
      const isActive = i < maxPlayers
      const isYou = id === playerId.value
      const emoji = playerEmojis[i]
      return { _key: id, name: info.name, isReady, isActive, isYou, emoji }
    })
  })

  const player = computed(() => players.value?.find(({ isYou }) => isYou))
  const isPlayerReady = computed(() => Boolean(player.value?.isReady))
  const isEveryoneReady = computed(() => {
    if (!players.value.length) return false
    return players.value
      .filter(({ isActive }) => isActive)
      .every(({ isReady }) => isReady)
  })

  const isGameFull = computed(() => !player.value?.isActive)
  const isGameOngoing = computed(() => {
    if (isGameFull.value) return false
    return Boolean(game.value?.currentQuestion) || isEveryoneReady.value
  })

  const isLastQuestion = computed(() => {
    if (!game.value) return false
    const { currentRound, currentQuestion } = game.value
    const lastQuestionKey =
      currentRound.questionKeys[currentRound.questionKeys.length - 1]
    return currentQuestion?._key === lastQuestionKey
  })

  const currentQuestion = computed(() => game.value?.currentQuestion)

  const showRoundIntro = computed(() => {
    if (!game.value || !currentQuestion.value) return true
    const [firstQuestionKey] = game.value.currentRound.questionKeys
    const currentQuestionKey = currentQuestion.value._key
    const isFirstQuestion = currentQuestionKey === firstQuestionKey
    return isFirstQuestion && !currentQuestion.value.startedAt
  })

  const subscribeChannel = () => {
    const channel = subscribe(code)

    channel.bind(
      'pusher:subscription_error',
      ({ err: message, status: statusCode, type }: PusherSubscriptionError) => {
        // https://github.com/pusher/pusher-js/blob/master/src/core/channels/channel.ts#L63
        channel.disconnect()

        if (statusCode === 401 || statusCode === 403) return

        const err = new Error(message)
        Sentry.captureException(Object.assign(err, { statusCode, type }))
      },
    )

    channel.bind(
      'pusher:subscription_succeeded',
      ({ me, members }: Members) => {
        const { id, info } = me as Member
        playerId.value = id
        channelMembers.value = { ...(members as MembersInfo), [id]: info }
      },
    )

    channel.bind('pusher:member_added', ({ id, info }: Member) => {
      channelMembers.value = { ...channelMembers.value, [id]: info }
    })

    channel.bind('pusher:member_removed', ({ id }: Member) => {
      const value = { ...channelMembers.value }
      delete value[id]
      channelMembers.value = value
    })

    channel.bind('mutate', setGame)

    return channel
  }

  return {
    game: computed(() => game.value),
    setGame,
    isLastQuestion,
    isGameLoaded,
    isGameOngoing,
    isPendingSubscribe,
    players,
    player,
    playerId,
    isPlayerReady,
    showRoundIntro,
    currentQuestion,
    isGameFull,
    isGameOver,
  }
})
