<template>
  <div :class="$style.steps">
    <div
      v-for="i in stepsCount"
      :key="i"
      :class="[
        $style.step,
        {
          [$style.selected]: activeStep === i - 1,
          [$style.fill]: activeStep >= i - 1,
        },
      ]"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const ConfettiProgressSteps = defineComponent({
  props: {
    stepsCount: Number,
    activeStep: Number,
  },
})

export default ConfettiProgressSteps
</script>

<style module>
.steps {
  display: flex;
  height: 0.25rem;
  justify-content: center;
}

.step {
  background-color: currentColor;
  border-radius: 0.25rem;
  height: 100%;
  margin: 0 0.25rem;
  opacity: 0.16;
  transition: width 0.5s ease, opacity 0.25s ease 0.25s;
  width: 1.5rem;
}

.step.fill,
.step.selected {
  opacity: 1;
}

.step.selected {
  transition: width 0.5s ease, opacity 0.25s ease;
  width: 8rem;
}
</style>
